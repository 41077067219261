import React from "react";
import { Link } from "react-router-dom";

import { FaBars, FaTimes, FaShoppingCart } from "react-icons/fa";

import Logo from "../assets/images/logo-color.png";
import { useGlobalContext } from "../context";

const Header = () => {
  const { isMenuOpen, setIsMenuOpen, cartItems } = useGlobalContext();

  if (isMenuOpen) {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  } else {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  }

  return (
    <header>
      <Link to="/" className="logo-container" id="top">
        <img src={Logo} alt="skillvyuh" className="logo-img" />
        <p className="logo-text">
          <span className="accent">S</span>KILL<span className="accent">V</span>
          YUH
        </p>
      </Link>
      <button
        className="menu-toggle"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>
      <nav className={isMenuOpen ? "menu-open" : "menu-close"}>
        <Link to="/" className="nav-link">
          Home
        </Link>
        <Link to="/training" className="nav-link">
          Trainings
        </Link>
        <Link to="/contact" className="nav-link">
          Contact Us
        </Link>
        <Link to="/cart" className="cart-btn">
          <FaShoppingCart />
          <span className="cart-items-number">{cartItems.length}</span>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
