import React from "react";
import trainings from "../assets/data/courses";
import { useGlobalContext } from "../context";

import { MdDeleteOutline, MdCurrencyRupee } from "react-icons/md";

const CartItem = ({ id }) => {
  const { setCartItems } = useGlobalContext();
  const item = trainings.filter((a) => a.id === id)[0];
  const title = item.title;
  return (
    <div className="cart-item">
      <img src={item.img} alt="" className="card-img" />
      <div className="course-details">
        <div className="left-aligned">
          <h2 className="course-title">{title}</h2>
          <p className="qty">Qty: 1</p>
        </div>
        <div className="right-aligned">
          <p className="course-price summary-amt">
            <MdCurrencyRupee />
            {Math.floor((item.price * item.discountRate) / 100)}
          </p>
          <button
            className="remove"
            onClick={() => {
              setCartItems((p) => p.filter((a) => a !== item.id));
            }}
          >
            <MdDeleteOutline />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
