import React from "react";
import { useGlobalContext } from "../context";

import "../css/Checkout.css";

const Checkout = () => {
  const { setCheckout } = useGlobalContext();
  return (
    <div className="checkout-modal">
      <h1 className="section-heading">Checkout</h1>
      <div className="checkout-window">
        <h2 className="sub-heading">Billing Details:</h2>
        <form>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <input type="tel" placeholder="Mobile Number" />
        </form>
        <button className="add-to-cart-btn" onClick={setCheckout}>
          pay now
        </button>
        <button
          className="remove"
          onClick={() => {
            setCheckout(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Checkout;
