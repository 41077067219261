import React from "react";
import { Link } from "react-router-dom";

import "../css/Home.css";

import logo from "../assets/images/logo.svg";

const Home = () => {
  return (
    <div className="hero-container">
      <div className="hero-text-container">
        <h1>
          Secure Your Digital Future with <span>SkillVyuh</span>
        </h1>
        <p>
          Start learning and growing with us by browsing our wide range of
          courses on various technology-related topics.
        </p>
        <Link to="/training" className="get-started-btn">
          Get Started
        </Link>
      </div>
      <img src={logo} alt="skillvyuh" className="center-logo" />
    </div>
  );
};

export default Home;
