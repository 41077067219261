import React from "react";
import { useGlobalContext } from "../context";
import { BsAlarm, BsCurrencyRupee } from "react-icons/bs";

const CourseCard = ({ course }) => {
  const { setModal, cartItems, handleAddBtnClick } = useGlobalContext();

  return (
    <article title="Click to Read More" className="course-card">
      <div className="image-container">
        <img src={course.img} alt={course.id} className="card-image" />
      </div>

      <div className="card-text-container">
        <h3 className="card-title">{course.title}</h3>
        <p
          className="course-details"
          onClick={() => {
            setModal({ open: true, details: course });
          }}
        >
          {course.courseDetails.substring(0, 200)}...{" "}
          <span className="read-more">Read More</span>
        </p>
        <p className="price">
          Course fee:{" "}
          <span className="new-price">
            <BsCurrencyRupee />
            {Math.floor((course.price * course.discountRate) / 100)}
          </span>
          <span className="old-price">
            <BsCurrencyRupee />
            {course.price}
          </span>{" "}
          {course.discountRate}% off
        </p>
        <p
          className="limited-time"
          style={{
            display: "flex",
            alignItems: "center",
            color: "rgb(255, 67, 67)",
            marginTop: "0.5rem",
          }}
        >
          <BsAlarm style={{ marginRight: "0.5rem" }} /> Limited Time Offer!
        </p>
        <button
          className={
            cartItems.includes(course.id)
              ? "remove-from-cart-btn"
              : "add-to-cart-btn"
          }
          onClick={(e) => {
            handleAddBtnClick(e, course);
          }}
        >
          {cartItems.includes(course.id) ? "remove from cart" : "add to cart"}
        </button>
      </div>
    </article>
  );
};

export default CourseCard;
