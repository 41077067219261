import React from "react";
import { MdCurrencyRupee } from "react-icons/md";
import { useGlobalContext } from "../context";
import CartItem from "./CartItem";
import Checkout from "./Checkout";

import "../css/Cart.css";

const Cart = () => {
  const { cartItems, getItemsTotal, checkout, setCheckout } =
    useGlobalContext();
  const itemsTotal = getItemsTotal();
  const gstAmount = itemsTotal * 0.18;
  const total = Math.round((itemsTotal + gstAmount) * 100) / 100;

  return (
    <>
      <section className="section-container cart-section">
        <h1 className="section-heading">Your Cart</h1>
        {cartItems.length > 0 ? (
          <div className="cart-container">
            <div className="cart-items-container">
              {cartItems.map((c) => {
                return <CartItem id={c} key={c} />;
              })}
            </div>
            <div className="order-summary-container">
              <h2 className="sub-heading">Order Summary</h2>
              <table className="order-summary-table">
                <tbody>
                  <tr className="summary-item">
                    <td className="summary-label">Items Total:</td>
                    <td className="summary-amt">
                      <MdCurrencyRupee />
                      {itemsTotal}
                    </td>
                  </tr>
                  <tr className="summary-item">
                    <td className="summary-label">GST (18%):</td>
                    <td className="summary-amt">
                      <MdCurrencyRupee />
                      {gstAmount}
                    </td>
                  </tr>
                  <tr className="summary-item">
                    <td className="summary-label total">Total:</td>
                    <td className="summary-amt">
                      <MdCurrencyRupee />
                      {total}
                    </td>
                  </tr>
                </tbody>
              </table>
              <button className="add-to-cart-btn" onClick={setCheckout}>
                Proceed to buy
              </button>
            </div>
          </div>
        ) : (
          <h1 className="sub-heading empty-cart">
            Looks like the cart is empty!
          </h1>
        )}
      </section>
      {checkout && <Checkout />}
    </>
  );
};

export default Cart;
